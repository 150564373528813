import React from 'react'

function Home() {
  return (
    <div className="home">
        <div className="name">
          <h1>GIAN T. CARLOS</h1>
        </div>
        <h2>FRONT-END DEVELOPER / WEB ACCESSIBILITY SPECIALIST</h2>
    </div>
  )
}

export default Home